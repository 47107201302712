import axios from '@/common/js/request'

export function selectPostPageList(params){
    const selectPostPageList = axios({
        url: '/post/selectPostPageList',
        method: 'get',
        params
    })
    return selectPostPageList
}
export function selectPostMaxSort(params){
    const selectPostMaxSort = axios({
        url: '/post/selectPostMaxSort',
        method: 'get',
        params
    })
    return selectPostMaxSort
}
export function deletePost(params){
    const deletePost = axios({
        url: '/post/deletePost',
        method: 'get',
        params
    })
    return deletePost
}
export function selectPostList(params){
    const selectPostList = axios({
        url: '/post/selectPostList',
        method: 'get',
        params
    })
    return selectPostList
}
export function savePost(data){
    const savePost = axios({
        url: '/post/savePost',
        method: 'post',
        data
    })
    return savePost
}
export function updatePost(data){
    const updatePost = axios({
        url: '/post/updatePost',
        method: 'post',
        data
    })
    return updatePost
}
