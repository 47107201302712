<template>
  <div class="contain">
    <div style="font-size: 14px;font-weight: bolder">
      岗位名称：
      <el-input size="mini" type="text" v-model="params.postname" placeholder="输入部门名称" style="width: 200px"></el-input>
      岗位编码：
      <el-input size="mini" type="text" v-model="params.postcode" placeholder="输入部门编码" style="width: 200px"></el-input>
      岗位状态：
      <el-select clearable  size="mini" style="width:130px;" class="EquipmentMarginLeft" v-model="params.generalStatus" placeholder="状态">
        <el-option
            v-for="item in userStatus"
            :key="item.id"
            :label="item.label"
            :value="item.id">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" style="margin-left:20px;" icon="el-icon-search" @click="search">查询</el-button>
      <el-button type="primary" size="mini" icon="el-icon-refresh" @click="recet">重置</el-button>
      <el-button type="success" size="mini" class="EquipmentMarginLeft"
                 @click="addmenu(0)" icon="el-icon-plus">添加岗位
      </el-button>
    </div>
    <el-table
        :data="tableData"
        style="width: 100%;margin: 10px 0;"
        class="table-no-search"
        row-key="id"
        border
        stripe
        :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
      <el-table-column prop="code" label="岗位编码" align="center"></el-table-column>
      <el-table-column prop="name" label="岗位名称" align="center"></el-table-column>
      <el-table-column prop="sort" label="岗位排序" align="center"></el-table-column>
      <el-table-column prop="generalStatus" label="状态" align="center">
        <template slot-scope="{row}">
          <el-tag v-if="row.generalStatus==0" type="danger">禁用</el-tag>
          <el-tag v-if="row.generalStatus==1" type="success">启用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
      <el-table-column label="操作" align="left">
        <template slot-scope="{row}">
          <span v-if="row.code=='sgry'||row.code=='xcgly'||row.code=='aj005'||row.code=='aj004'||row.code=='aj002'||row.code=='aj001'">系统岗位</span>
          <div v-else>
          <el-button type="text" @click="updataUser(row)" v-if="row.code!='aj001'&&row.code!='aj002'&&row.code!='aj003'" size="small">编辑</el-button>
          <el-button type="text" @click="del(row)" v-if="row.code!='aj001'&&row.code!='aj002'&&row.code!='aj003'" size="small" style="color:red">删除</el-button>
          </div>
          
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          style="margin-top:10px;"
          background
          layout="sizes,prev, pager, next,jumper"
          :total="total"
          :page-sizes="[10,30,50,100]"
          :page-size="params.size"
          :current-page="params.current"
          @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
        :title="title==1?'添加岗位信息':'编辑岗位信息'"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        @close="clearnF('form')">
      <el-form ref="form" :model="form" label-width="100px" :rules="rule">
        <el-form-item label="岗位编码：" prop="code">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
          <el-form-item label="岗位名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="岗位排序：" prop="sort">
            <el-input v-model="form.sort" type="number" :min="1"></el-input>
          </el-form-item>
        <el-form-item label="岗位状态：">
          <el-radio-group v-model="form.generalStatus">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="title==1" type="primary" @click="submitfrom('form')">确 定</el-button>
        <el-button v-else type="primary" @click="updatasubmitfrom('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {savePost,deletePost, selectPostPageList, selectPostMaxSort, updatePost} from '../../RequestPort/user/positionJob'
export default {
  name: "positionJob",
  data(){
    return{
      tableData:[],
      userStatus:[{label:'启用',id:1},{label:'禁用',id:0}],
      title:null,
      form:{
        generalStatus:1,
        sort:'',
        code:'',
        remark:'',
        name:''
      },
      rule:{
        name:[{ required: true, message: '请输入岗位名称', trigger: 'blur' }],
        code:[{ required: true, message: '请输入岗位编码', trigger: 'blur' }],
        sort:[{ required: true, message: '请输入岗位排序', trigger: 'blur' }],
      },
      dialogVisible:false,
      params:{
        current:1,
        size: 100,
        generalStatus:'',
        postname:'',
        postcode:''
      },
      total:null
    }
  },
  mounted() {
    this.loadList(this.params)
  },
  methods:{
    currentChange(val){
      this.params.current = val
      this.loadList(this.params)
    },
    handleSizeChange(val){
      this.params.size = val
      this.loadList(this.params)
    },
    addmenu(){
      this.title = 1
      this.dialogVisible =!this.dialogVisible
      selectPostMaxSort().then((res)=>{
        this.form.sort = res.data
        this.$forceUpdate()
      })

    },
    submitfrom(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        savePost(this.form).then((res)=>{
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.dialogVisible = false
              this.loadList(this.params)
            } else {
              this.$message.error(res.msg)
            }
        })
      })
    },
    updatasubmitfrom(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        updatePost(this.form).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg)
            this.dialogVisible = false
            this.loadList(this.params)
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    search(){
      this.params.current = 1
      this.loadList(this.params)
    },
    recet(){
      this.params={
        current:1,
        size: 100,
        generalStatus:''
      }
      this.loadList(this.params)
    },
    updataUser(row){
      this.title = 0
      this.dialogVisible =!this.dialogVisible
      this.form = JSON.parse(JSON.stringify(row))
    },
    del(row){
      this.$confirm(`是否删除岗位---[ ${row.name} ]?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        deletePost({postId:row.postId}).then((e)=>{
          if(e.code===200){
            this.$message.success(e.msg)
            this.loadList(this.params)
          }else{
            this.$message.error(e.msg)
          }
        })
      })
    },
    clearnF(form){
      this.form={
        generalStatus:1
      }
      this.$refs[form].resetFields()
    },
    loadList(obj){
      selectPostPageList(obj).then((res)=>{
        this.tableData =  res.data.records
        this.total = res.data.total
      })
    }
  }
}
</script>

<style scoped>

</style>
